<template>
    <div class="myprize">
        <div class="list" v-for="(item,index) in list" :key="index">
            <div>
                <p>{{ item.title }}</p>
                <p>{{ dayjstime(item.ctime) }}</p>
            </div>
            <div @click="link(item.link)" v-if="item.link">去查看</div>
        </div>
        <div class="jiangp" v-if="list.length<1">
            <img src="https://ppyos.xijiuyou.com/202203/card/gift-open.png" alt="" />
            <p>还未获得奖品哦~</p>
        </div>
    </div>
</template>
<script>
import welfare from "../../api/welfare";
import dayjs from 'dayjs';
export default{
    data(){
        return{
            page:1,
            list:[],
        }
    },
    mounted(){
        const that = this;
        that.prizeList();
        window.addEventListener("scroll",function(){
      //加载更多
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight && scrollTop > 100) {
        //写后台加载数据的函数
        that.page ++;
        that.prizeList();
      }
    });
    },
    methods:{
        dayjstime(item){
            return dayjs(item).format("YYYY-MM-DD HH:mm"); 
        },
        link(link){
            window.location.href = link;
        },
        prizeList(){
            const data={
                page:this.page,
                pageSize:20
            }
            welfare.prizeList(data).then(res=>{
                console.log(res)
               this.list.push(...res.list)
            })
        },
    }
}
</script>
<style lang="less" scoped>
.myprize{
    width:100vw;
    min-height:100vh;
    background:#ffffff;
    padding:0 14px;
    .list{
        width:100%;
        border-bottom:1px solid #eeeeee;
        display:flex;
        justify-content:space-between;
        height:70px;
        align-items: center;
        div:nth-child(1){
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            p:nth-child(2){
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                margin-top:5px;
            }
        }
        div:nth-child(2){
            width: 54px;
            height: 24px;
            background: rgba(255,134,134,0.12);
            border-radius: 13px;
            border: 1px solid #FF8E8E;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FF2A2A;
            text-align: center;
            line-height: 24px;
        }
    }
    .jiangp{
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        img{
            margin-top: 180px;
            width: 134px;
        }
        p{
            margin-top: 15px;
        }
    }
}
</style>